export interface StatisticItemProps {
  label: string
  statistic?: string
}

export const StatisticItem = ({ label, statistic }: StatisticItemProps) => {
  return (
    <div className='flex flex-col text-mostlyGreen border-l-[5px] border-l-mostlyGreen px-[10px] md:px-5 gap-0 sm:gap-[5px]'>
      <div className='min-h-[45px] text-[30px] sm:min-h-[69px] sm:text-[46px] font-bold'>
        {statistic}
      </div>
      <div className='text-[14px] font-medium'>{label}</div>
    </div>
  )
}
