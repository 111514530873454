import dayjs from 'dayjs'
import { useState } from 'react'

import { CallToActionCarousel } from 'src/components/molecules/CallToActionCarousel/CallToActionCarousel'
import { PurchaseNotification } from 'src/components/molecules/PurchaseNotification'

import { GetLatestUserEventQuery } from 'src/config/generated/graphql'
import { Carousel as CarouselType } from 'src/config/generated/sanitySchema'
import { ArrayElement } from 'src/types/common'
import { trpc } from 'src/utils/trpc'

interface Props {
  data: CarouselType | undefined
}

export const CtaCarouselSection = ({ data }: Props) => {
  const [lastPolled, setLastPolled] = useState<string | null>(null)
  const [notification, setNotification] = useState<
    ArrayElement<GetLatestUserEventQuery['UserEventView']> | undefined
  >(undefined)

  trpc.useQuery(
    [
      'notifications.purchase',
      {
        lastPolled
      }
    ],
    {
      refetchInterval: 10_000,
      onSettled: (
        result:
          | ArrayElement<GetLatestUserEventQuery['UserEventView']>
          | undefined
      ) => {
        setLastPolled(dayjs().toString())
        if (result) setNotification(result)
      }
    }
  )

  if (!data || !data.slides?.length) {
    return null
  }

  return (
    <div className='relative'>
      <CallToActionCarousel
        isNotificationVisible={!!notification}
        slides={data.slides}
      />
      {notification ? (
        <PurchaseNotification
          notification={notification}
          onCloseClick={() => setNotification(undefined)}
        />
      ) : null}
    </div>
  )
}
