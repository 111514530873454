import { LoadingSpinner } from 'src/components/atoms/LoadingSpinner'
import { SanityPageComponent } from 'src/components/layouts/Page/SanityPage'
import { BlogPostBackgroundColor } from 'src/components/molecules/BlogPosts/types'
import { NewsletterSignup } from 'src/components/organisms/NewsletterSignup'

import { Carousel as CarouselType } from 'src/config/generated/sanitySchema'
import { NotFound } from 'src/pages/404/404'

import {
  TakeActionSection,
  NewsAndStoriesSection,
  StatisticsSection,
  AudienceSection,
  FocusSection
} from '.'
import { CtaCarouselSection } from './CtaCarouselSection'
import { ProjectsSection } from './ProjectsSection'
import { getInfoPanelData } from './helpers'

export const Home: SanityPageComponent = ({ data: pageData, isLoading }) => {
  // Redirect if page not found
  if (!isLoading && !pageData) {
    return <NotFound />
  }

  const carouselData = pageData?.content?.find(
    c => c._type === 'carousel' && c.title === 'home'
  ) as CarouselType | undefined

  const impactSectionData = getInfoPanelData(pageData, 'impact')
  const takeActionSectionData = getInfoPanelData(pageData, 'action')
  const audienceSectionData = getInfoPanelData(pageData, 'audience')
  const focusSectionData = getInfoPanelData(pageData, 'focus')
  const newsLetterSectionData = getInfoPanelData(pageData, 'contact')
  const projectsSectionData = getInfoPanelData(pageData, 'featuredProjects')

  if (isLoading) return <LoadingSpinner isLoading />

  return (
    <>
      <CtaCarouselSection data={carouselData} />
      <StatisticsSection cmsData={impactSectionData} />
      <TakeActionSection cmsData={takeActionSectionData} />
      <ProjectsSection
        cmsData={projectsSectionData}
        className='lg:!pt-[100px]'
      />
      <AudienceSection cmsData={audienceSectionData} />
      <NewsAndStoriesSection
        featuredOn='homePage'
        backgroundColor={BlogPostBackgroundColor.white}
      />
      <FocusSection cmsData={focusSectionData} />
      <NewsletterSignup cmsData={newsLetterSectionData} />
    </>
  )
}
