/* eslint-disable jsx-a11y/alt-text */
import { FitMode, SanityImageSource } from '@sanity/image-url/lib/types/types'
import NextImage, { ImageProps as NextImageProps } from 'next/image'
import { useEffect, useState } from 'react'

import { getSanityImageUrl } from 'src/utils/sanity'

const DEFAULT_IMG_SRC = '/img/default.png'

interface SanityImageProps
  extends Omit<NextImageProps, 'src' | 'width' | 'height' | 'unoptimized'> {
  fallbackSrc?: NextImageProps['src']
  source: SanityImageSource | undefined
  width?: number
  height?: number
  sourceWidth?: number
  sourceHeight?: number
  fitMode?: FitMode
}

export const SanityImage = ({
  fallbackSrc = DEFAULT_IMG_SRC,
  source,
  width,
  sourceWidth,
  sourceHeight,
  fitMode,
  height,
  ...props
}: SanityImageProps) => {
  const [imgSrc, setImgSrc] = useState<NextImageProps['src']>()
  useEffect(() => {
    try {
      if (!source) setImgSrc(fallbackSrc)
      else {
        const url = getSanityImageUrl(
          source,
          sourceWidth,
          sourceHeight,
          fitMode
        )
        setImgSrc(url)
      }
    } catch (error) {
      setImgSrc(fallbackSrc)
    }
  }, [source, fallbackSrc, sourceWidth, sourceHeight, fitMode])

  if (!imgSrc) return null

  return (
    <NextImage
      unoptimized
      width={width}
      height={height}
      src={imgSrc}
      {...props}
      onError={() => setImgSrc(fallbackSrc)}
    />
  )
}
