import { Image } from 'src/components/atoms/Image'
import { ButtonLinkProps } from 'src/components/atoms/Link'
import { Heading1, ParagraphIntro } from 'src/components/atoms/Typography'

import { Slide as SlideType } from 'src/config/generated/sanitySchema'
import { getSanityImageUrl } from 'src/utils/sanity'

import { CallToActionButtonLink } from '../CallToActionButtonLink'

export const Slide = ({
  backgroundImage,
  title,
  buttons,
  subtitle,
  isNotificationVisible
}: SlideType & { isNotificationVisible?: boolean }) => {
  const mobileBottomMargin = isNotificationVisible ? 'mb-[150px]' : 'mb-[60px]'

  const getCtaProps = (index: number): Partial<ButtonLinkProps> => ({
    color: index === 0 ? 'primary' : 'white',
    variant: index === 0 ? 'contained' : 'text',
    className: index !== 0 ? 'text-[16px]' : '',
    size: index === 0 ? 'large' : 'base'
  })

  return (
    <div
      key={title}
      className='flex flex-row justify-center relative h-[inherit] overflow-hidden home-slide'
    >
      {backgroundImage && (
        <Image
          className='absolute object-cover inset-0 -z-10'
          alt={title}
          src={getSanityImageUrl(backgroundImage)}
          fill
        />
      )}
      <div
        className={`flex-1 mx-5 md:mx-[110px] mt-[60px] md:mt-[100px] max-w-[1148px] ${mobileBottomMargin} md:mb-[100px]`}
      >
        <div className='md:max-w-[570px] max-w-none bg-[rgba(0,0,0,0.6)] flex flex-col gap-5 text-white p-5 md:p-10'>
          <Heading1 className='!text-white z-10 max-md:text-[32px] !m-0'>
            {title
              .split('\n')
              .flatMap((line, index) =>
                index !== 0 ? [<br key={index} />, line] : line
              )}
          </Heading1>
          <ParagraphIntro className='text-white'>{subtitle}</ParagraphIntro>
          <div className='flex flex-col md:flex-row md:items-center items-start gap-5'>
            {buttons &&
              buttons?.map((button, index) => (
                <CallToActionButtonLink
                  isExternal={button.linkType === 'external'}
                  href={
                    button.linkType === 'internal'
                      ? (button.internalLink as any)?.path || ''
                      : button.externalUrl || ''
                  }
                  key={button._key}
                  {...getCtaProps(index)}
                >
                  {button.text}
                </CallToActionButtonLink>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
