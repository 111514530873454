import { InternalLink } from 'src/components/atoms/Link'
import { ImageDetailTile } from 'src/components/molecules/ImageDetailTile'

import { viewProjectPath } from 'src/config/paths'

type ProjectTileProps = {
  ImageUrl: string
  RegionName?: string
  ProjectName: string
  DescriptionValue?: string
  DescriptionText?: string
  Purpose: string[]
  Uri: string
  className?: string
}

export const ProjectTile = ({
  ImageUrl,
  RegionName,
  ProjectName,
  DescriptionValue,
  DescriptionText,
  Purpose,
  Uri,
  className = ''
}: ProjectTileProps) => {
  return (
    <InternalLink
      className={`hover:shadow-[0px_0px_20px_0px_rgba(0,_0,_0,_0.25)] transition-shadow duration-300 ${className}`}
      href={viewProjectPath({
        routeParams: {
          uri: Uri
        }
      })}
    >
      <ImageDetailTile
        image={ImageUrl}
        tileName={ProjectName}
        labelText={DescriptionText}
        labelValue={DescriptionValue}
        location={RegionName}
        tags={Purpose}
      />
    </InternalLink>
  )
}
