import { Infopanel } from 'src/config/generated/sanitySchema'
import { PageQueryResult } from 'src/server/router/sanity/groq/post/types'

export const getInfoPanelData = (
  pageData: PageQueryResult | undefined,
  panelType: Infopanel['panelType']
): Infopanel => {
  return pageData?.content?.find(
    c => c._type === 'infopanel' && c.panelType === panelType
  ) as Infopanel
}
