import { PostsQueryPost } from 'src/server/router/sanity/groq/post/types'

import { BlogPostCard } from '../BlogPostCard'
import { BlogPostBackgroundColor } from './types'

type BlogPostsProps = {
  backgroundColor?: BlogPostBackgroundColor
  posts?: PostsQueryPost[]
}

function determineCardStyles(backgroundColor: BlogPostBackgroundColor) {
  switch (backgroundColor) {
    case BlogPostBackgroundColor.white:
      return 'bg-linen'
    default:
      return 'bg-white'
  }
}

const BlogPosts = ({
  posts = [],
  backgroundColor = BlogPostBackgroundColor.white
}: BlogPostsProps) => {
  const cardStyle = determineCardStyles(backgroundColor)
  return (
    <div
      className={`grid gap-10 md:grid-cols-2 xl:grid-cols-3 bg-${backgroundColor}`}
    >
      {posts.map(post => {
        return (
          <BlogPostCard
            key={post.slug.current}
            post={post}
            className={cardStyle}
          />
        )
      })}
    </div>
  )
}

export default BlogPosts
