import { Form, Formik } from 'formik'

import { Button } from 'src/components/atoms/Button'
import { ExternalLink } from 'src/components/atoms/Link'
import { LoadingSpinner } from 'src/components/atoms/LoadingSpinner'
import { ParagraphSmaller } from 'src/components/atoms/Typography'
import { FormField } from 'src/components/molecules/FormField'

import { FormFieldTypes } from 'src/types/form'
import { inferMutationInput } from 'src/utils/trpc'

import { validationSchema } from './validationSchema'

type FormValuesType = Omit<
  inferMutationInput<'emails.newsletterSignup'>,
  'token'
>

const INITIAL_VALUES: FormValuesType = {
  firstName: '',
  lastName: '',
  email: ''
}

export interface Props {
  isLoading: boolean
  onSubmit: (values: FormValuesType) => void
}

export const NewsletterSignupForm = ({ isLoading, onSubmit }: Props) => {
  const handleOnSubmit = (values: FormValuesType) => {
    if ('gtag' in window && typeof window.gtag === 'function')
      window.gtag('event', 'form_submission', {
        form_name: 'sign_up'
      })

    onSubmit(values)
  }
  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <LoadingSpinner isLoading={isLoading}>
              <div className='flex flex-col'>
                <FormField
                  id='email'
                  type={FormFieldTypes.text}
                  label='Email'
                  formFieldClassName='bg-white'
                />
                <div className='flex flex-col xl:flex-row xl:gap-5'>
                  <FormField
                    id='firstName'
                    type={FormFieldTypes.text}
                    label='First name'
                    formFieldClassName='bg-white'
                  />
                  <FormField
                    id='lastName'
                    type={FormFieldTypes.text}
                    label='Last name'
                    formFieldClassName='bg-white'
                  />
                </div>
                <ParagraphSmaller>
                  This site is protected by reCAPTCHA and the Google{' '}
                  <ExternalLink
                    color='black'
                    href='https://policies.google.com/privacy'
                  >
                    Privacy Policy
                  </ExternalLink>{' '}
                  and{' '}
                  <ExternalLink
                    color='black'
                    href='https://policies.google.com/terms'
                  >
                    Terms of Service
                  </ExternalLink>{' '}
                  apply.
                </ParagraphSmaller>
                <Button
                  className='self-start'
                  disabled={isSubmitting}
                  type='submit'
                  color='tertiary'
                  size='large'
                >
                  Subscribe
                </Button>
              </div>
            </LoadingSpinner>
          </Form>
        )
      }}
    </Formik>
  )
}
