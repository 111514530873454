import { useEffect, useRef } from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import Slider, { CustomArrowProps, Settings } from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import { TallArrowLeft, TallArrowRight } from 'src/components/atoms/Icons'

interface SlickButtonFixProps extends CustomArrowProps {
  children?: React.ReactNode
}

const SlickButtonFix = ({
  currentSlide,
  slideCount,
  children,
  className,
  ...props
}: SlickButtonFixProps) => (
  <button
    type='button'
    className={`focus-shadow !h-[45px] lg:!h-[60px] !w-[15px] lg:!w-5 ${className}`}
    {...props}
  >
    {children}
  </button>
)

interface CarouselProps extends Settings {
  controlsPosition?: 'inside' | 'outside'
  useSmallArrows?: boolean
  selectedSlide?: number
}

export const Carousel = ({
  className = '',
  controlsPosition = 'inside',
  useSmallArrows = false,
  children,
  selectedSlide,
  ...rest
}: CarouselProps) => {
  const sliderRef = useRef<Slider>(null)
  const arrowClasses =
    controlsPosition === 'inside' ? 'fill-white' : 'fill-black'

  const settings: Settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    fade: false,
    infinite: false,
    useTransform: true,
    speed: 180,
    cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
    dotsClass: 'custom-slick-dots',

    prevArrow: (
      <SlickButtonFix aria-label='Previous slide'>
        {useSmallArrows ? (
          <FaChevronLeft />
        ) : (
          <TallArrowLeft className={arrowClasses} />
        )}
      </SlickButtonFix>
    ),
    nextArrow: (
      <SlickButtonFix aria-label='Next slide'>
        {useSmallArrows ? (
          <FaChevronRight />
        ) : (
          <TallArrowRight className={arrowClasses} />
        )}
      </SlickButtonFix>
    ),
    ...rest
  }

  useEffect(() => {
    if (selectedSlide !== undefined) sliderRef.current?.slickGoTo(selectedSlide)
  }, [selectedSlide, sliderRef])

  return (
    <Slider
      ref={sliderRef}
      className={`${className} slick-controls-${controlsPosition} ${
        useSmallArrows ? 'slick-controls-small-arrows' : ''
      }`}
      {...settings}
    >
      {children}
    </Slider>
  )
}
