import { Carousel } from 'src/components/atoms/Carousel'

import { Slide as SlideType } from 'src/config/generated/sanitySchema'

import { Slide } from './Slide'

interface Props {
  slides: SlideType[]
  isNotificationVisible?: boolean
}

export const CallToActionCarousel = ({
  slides,
  isNotificationVisible
}: Props) => {
  return (
    <Carousel infinite={slides.length > 1} dots={slides.length > 1}>
      {slides.map((slide, idx) => (
        <Slide
          key={`slide-${idx}`}
          {...slide}
          isNotificationVisible={isNotificationVisible}
        />
      ))}
    </Carousel>
  )
}
